import $ from 'jquery'


export function handleDownload(configurations) {
    for (const configuration of configurations) {
        $('#download_form_' + configuration.elementId).on('submit', function(e) {
            $('#download_modal_' + configuration.elementId).modal('toggle');
        });
        
        $('#download_modal_' + configuration.elementId).on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);
            var id = button.data('id');
            $('#fileid_' + configuration.elementId).val(id);
        });      
    }
}
