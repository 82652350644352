import 'toastr/build/toastr.min.css';
import toastr from 'toastr';

export function handleContact(configurations) {
    window.toastr = toastr;
    for (const configuration of configurations) {
        var sendButtonId = '#contact-button_' + configuration.elementId;
        var refreshButtonId = '#refresh_' + configuration.elementId;
        $(sendButtonId).prop('disabled', true);

        var requiredFields = [];

        requiredFields.push('message_' +  configuration.elementId);
        if (configuration.show_sender_name)
            requiredFields.push('name_' +  configuration.elementId);
        if (configuration.show_sender_email)
            requiredFields.push('email_' +  configuration.elementId);
        if (configuration.show_subject)
            requiredFields.push('subject_' +  configuration.elementId);
        if (configuration.show_agreement)
            requiredFields.push('agreement_' +  configuration.elementId);

        if (areFilled(requiredFields))
            $(sendButtonId).prop('disabled', false);

        requiredFields.forEach(element => {
            $('#' + element).on('keyup change', function() {
                if (areFilled(requiredFields))
                    $(sendButtonId).prop('disabled', false);
                else
                    $(sendButtonId).prop('disabled', true);
            });
        });
        reloadCaptchaId();

        $(refreshButtonId).on('click', function() {
            reloadCaptchaId()
        });
        $(sendButtonId).on('click', function() {
            var name = $('#name_' + configuration.elementId).val();
            var email = $('#email_' + configuration.elementId).val();
            var subject = $('#subject_' + configuration.elementId).val();
            var message = $('#message_' + configuration.elementId).val();
            var captcha = $('#captcha_' + configuration.elementId).val();
            var captchaId = $('#captchaid_' + configuration.elementId).val();
            var payload = { name: name, 
                            email: email, 
                            subject: subject, 
                            message: message, 
                            captcha: captcha, 
                            captchaId: captchaId, 
                            elementId: configuration.elementId
                         };
            $(sendButtonId).prop('disabled', true);
        
            $.post( "api/contact", payload, function( data, code, jqXHR ) {
                if (jqXHR.status === 201) {

                    requiredFields.forEach(element => {
                        if ($('#' + element).is(':checkbox')) {
                            $('#' + element).prop('checked', false);
                        } else if ($('#' + element).hasClass('summernote')) {
                            $('#' + element).summernote('code','');
                        } else {
                            $('#' + element).val('');
                        }
                    });
        
                    toastr.options.closeMethod = 'fadeOut';
                    toastr.options.closeDuration = 200;
                    toastr.options.closeEasing = 'swing';
                    toastr.success(configuration.success_message);
                } 
            }).fail(function( jqXHR, textStatus, errorThrown ) {
                if (jqXHR.status === 422) {            
                    toastr.error('Nieprawidłowy kod z obrazka');
                } else {
                    toastr.error('Błąd przy wysyłaniu wiadomości');
                }
                if (areFilled(requiredFields))
                    $(sendButtonId).prop('disabled', false);    
            }); 
        });

        function reloadCaptchaId() {
            const captchaId = crypto.randomUUID();
            $('#captchaid_' + configuration.elementId).val(captchaId);
            document.getElementById('captchaimage_' + configuration.elementId).src = 'captcha.php?captchaId=' + captchaId;    
        }
 
        function areFilled(requiredFields) {
            for (const element of requiredFields) {
                if (element.startsWith('email_')) {
                    if (!validateEmail($('#' + element).val()))
                        return false;
                }
                if (($('#' + element).is(':checkbox') &&  !$('#' + element).is(':checked')) ||
                (!$('#' + element).is(':checkbox') && $('#' + element).val() === '')) {
                    return false;
                }
            };
            return true;
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }      
}