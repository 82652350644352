import $ from 'jquery'
import _, { map } from 'underscore';
import lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
import lozad from 'lozad';

const mode = {
	LAZY: 1,
	NORMAL: 2,
}

lightbox.option({
    "fadeDuration": 300,
    "resizeDuration": 500,
    "albumLabel" : "Zdjęcie %1 z %2"
});


export function handleAlbum(configurations) {
    for (const configuration of configurations) {
        if (parseInt(configuration.mode) === mode.NORMAL) {
            getAlbum(configuration, 1, configuration.albumId);
            clearCurrentPage(configuration.elementId);
            $('#prev_' + configuration.elementId).hide();

            document.getElementById("prev_" + configuration.elementId).addEventListener('click', function() {
                prevPhotos(configuration, configuration.albumId);
            });

            document.getElementById("next_" + configuration.elementId).addEventListener('click', function() {
                nextPhotos(configuration, configuration.albumId);
            });
        } else {
            lozad('.lozad', {
                threshold: 0.9,
                enableAutoReload: true,
                load: function(el) {
                    el.src = el.dataset.src;
                    el.onload = function() {
                        el.classList.add('fade-img')
                    }
                }
              }).observe();
        }
    }
}

export function handleAlbumList(configurations) {
    for (const configuration of configurations) {
        clearCurrentPage(configuration.elementId);

        getGalleryAlbums(configuration, 1);
        
        $('#prev_' + configuration.elementId).hide();
        
        let yearsButtons = document.getElementsByClassName("year_" + configuration.elementId);
        Array.from(yearsButtons).forEach(function(element) {
            element.addEventListener('click', function() {
                setYear(this.getAttribute('data-value'), configuration);
            });
        });

        document.getElementById("yearall_" + configuration.elementId).addEventListener('click', function() {
            setYear('', configuration);
        });

        document.getElementById("prev_" + configuration.elementId).addEventListener('click', function() {
            prevPage(configuration);
        });

        document.getElementById("next_" + configuration.elementId).addEventListener('click', function() {
            nextPage(configuration);
        });
    }
}


function setYear(year, configuration) {
    clearCurrentPage(configuration.elementId);
    $('#year_' + configuration.elementId).val(year);
    $('#year_filter_' + configuration.elementId + ' .btn-marked').removeClass('btn-marked');
    if (year === 'none')
        $('#yearnone_' + configuration.elementId).addClass('btn-marked');
    else if (!year)
        $('#yearall_' + configuration.elementId).addClass('btn-marked');
    else
        $('#year_' + year + '_' + configuration.elementId).addClass('btn-marked');
    getGalleryAlbums(configuration, 1);
}

function getYear(elementId) {
    return $('#year_' + elementId).val();
}

function clearCurrentPage(elementId) {
    $('#page_' + elementId).val(1);
    $('#prev_' + elementId).hide();
}

function getCurrentPage(elementId) {
    return $('#page_' + elementId).val();
}

function decreasePage(elementId) {
    let currentPage = getCurrentPage(elementId);
    $('#page_' + elementId).val(currentPage - 1);
    return currentPage - 1;
}

function increasePage(elementId) {
    let currentPage = parseInt(getCurrentPage(elementId)) + 1;
    $('#page_' + elementId).val(currentPage);
    return currentPage;
}

function showSpinner(elementId) {
    $('#spinner_' + elementId).show();
}

function hideSpinner(elementId) {
    $('#spinner_' + elementId).hide();
}

function disableButtons(elementId) {
    $('#prev_' + elementId).attr('disabled', true);
    $('#next_' + elementId).attr('disabled', true);
    showSpinner(elementId);
}

function enableButtons(elementId) {
    $('#prev_' + elementId).removeAttr('disabled');
    $('#next_' + elementId).removeAttr('disabled');
    hideSpinner(elementId);
}

function prevPage(configuration) {
    disableButtons(configuration.elementId);
    let currentPage = getCurrentPage(configuration.elementId);
    if (currentPage > 1) {
        currentPage = decreasePage(configuration.elementId);
        getGalleryAlbums(configuration, currentPage );
    }
    if (currentPage === 1)
        $('#prev_' + configuration.elementId).hide();
}

function nextPage(configuration) {
    disableButtons(configuration.elementId);

    $('#prev_' + configuration.elementId).show();
    let currentPage = increasePage(configuration.elementId);
    getGalleryAlbums(configuration, currentPage);
}

function prevPhotos(configuration, albumId) {
    let currentPage = getCurrentPage(configuration.elementId);
    disableButtons(configuration.elementId);
    if (currentPage > 1) {
        currentPage = decreasePage(configuration.elementId);
        getAlbum(configuration, currentPage, albumId );
    }
    if (currentPage === 1)
        $('#prev_' + configuration.elementId).hide();
}

function nextPhotos(configuration, albumId) {
    disableButtons(configuration.elementId);

    $('#prev_' + configuration.elementId).show();
    let currentPage = increasePage(configuration.elementId);
    getAlbum(configuration.elementId, currentPage, albumId );
}

function getGalleryAlbums(configuration, currentPage) {
    var albums = $('#albums_' + configuration.elementId);
    albums.empty();
    var col = 12 / configuration.columns;
    var templateHtml = '<div class="col-sm-'+ col +'">\n' +
                        '   <div class="gallery__container">\n' +
                        '       <div class="gallery__thumb">\n' +
                        '           <a href="album,<%= id %>.html">\n' +
                        '           <div class="gallery__thumb__container">\n' +
                        '               <img src="<%= photo %>" class="gallery-thumb__img img-responsive" alt="<%= name %>">\n';
    if (configuration.showDate === '1')
        templateHtml += '               <div class="caption"><%= date %></div>\n';
    templateHtml +=     '           </div>\n' +
                        '           <h5 class="gallery-thumb__title"><%= name %></h5>\n' +
                        '           </a>\n' +
                        '       </div>\n';
    templateHtml +=     '   </div>\n' +
                        '</div>';
    
    var template = _.template(templateHtml);

    var sortBy = configuration.sortBy == null ? 0 : configuration.sortBy;
    var sortOrder = configuration.sortDirection == null ? 0 : configuration.sortDirection;
    var useCreated = configuration.useCreatedDate == null ? 0 : configuration.useCreatedDate;
    var yearFilter = configuration.yearFilter == null ? 0 : configuration.yearFilter;
    var yearValue = yearFilter == 0 ? 0 : getYear(configuration.elementId) !== '' ? getYear(configuration.elementId) : 0;

    var address = "api/gallery/get/" + configuration.category + "/" + yearValue + "/" + yearFilter + "/" + sortBy + "/" + sortOrder + "/" + useCreated;
    if (configuration.pagination) {     
        address = "api/gallery/get/" + configuration.category + "/" + yearValue + "/" + yearFilter + "/" + sortBy + "/" + sortOrder + "/" + useCreated + "/" + (currentPage - 1)+ "/" + configuration.countPerPage;
    }
    $.getJSON( address, function( data ) {
        var photos = [];
        $.each( data['galleries'], function( key, val ) {
            photos.push(template({
                name: val['name'],
                    photo: val['photo'],
                    id: val['id'],
                    date: val['date']
            }));
        });
        for (let i=0;i<Math.ceil(photos.length / configuration.columns); i++) {
            var div = $('<div>').attr('class','row');
            albums.append(div);
            for (let cols=0;cols<configuration.columns; cols++) {
                div.append(photos[configuration.columns*i + cols]);
            }
        }
        enableButtons(configuration.elementId);
        if (configuration.pagination) {
            var count = parseInt(data['count']);
            if (count > configuration.countPerPage)
                $('#pager_' + configuration.elementId).show();
            if (getCurrentPage(configuration.elementId) * configuration.countPerPage >= count)
                $('#next_' + configuration.elementId).hide();
            else {
                $('#next_' + configuration.elementId).show();
            }
        }
    });
}

function getAlbum(configuration, currentPage, albumId) {
    var album = $('#album_' + configuration.elementId);
    album.empty();
    var col = 12 / configuration.columns;
    var template = _.template('<div class="col-sm-'+ col +'">\n' +
        '    <div class="gallery__thumb">\n' +
        '    <a href="<%= photo %>" data-lightbox="album-' + configuration.elementId + '[]">\n' +
        '        <img src="<%= thumb %>" class="gallery-thumb__img img-responsive" alt="<%= name %>">\n' +
        '        <h5 class="gallery-thumb__description"><%= description %></h5>\n' +
        '    </a>\n' +
        '    </div>\n' +
        '</div>');
    var id = albumId;
    var address = "api/album/get/" + id;
    if (configuration.countPerPage) {
        address = "api/album/get/" + id + "/" + (currentPage - 1)+ "/" + configuration.countPerPage;
    }
    $.getJSON( address, function( data ) {
        var photos = [];
        $.each( data['album'], function( key, val ) {
            photos.push(template({
                description: val['description'],
                photo: val['photo'],
                thumb: val['photo'],
                id: val['id']
            }));
        });
        for (let i=0;i<Math.ceil(photos.length / configuration.columns); i++) {
            var div = $('<div>').attr('class','row');
            album.append(div);
            for (let cols=0;cols<configuration.columns; cols++) {
                div.append(photos[configuration.columns*i + cols]);
            }
        }
        enableButtons(configuration.elementId);
        if (configuration.countPerPage) {
            var count = parseInt(data['count']);
            if (count > configuration.countPerPage)
                $('#pager_' + configuration.elementId).show();
            if (currentPage * configuration.countPerPage >= count)
                $('#next_' + configuration.elementId).hide();
            else
                $('#next_' + configuration.elementId).show();
        }
    });
}