import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel'

export function handleCarousel(configurations) {
    for (const configuration of configurations) {
        $('#carousel_' + configuration.elementId).owlCarousel({
            margin:10,
            nav: false,
            dots: false,
            loop: configuration.loop === 1 ? 'true' : 'false',
            rewind: true,
            center: configuration.center === 1 ? 'true' : 'false',
            responsiveClass:true,
            autoplay: configuration.autoplay === 1 ? 'true' : 'false',
            autoplayTimeout: 2000,
            responsive:{
                0:{
                    items:1,
                    nav:false
                },
                600:{
                    items:3,
                    nav:false
                },
                1000:{
                    items:5,
                    nav:false,
                    loop:false
                }
            }
        })
    }
}