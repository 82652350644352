import $ from 'jquery'

 
export default $.fn.dropDownMenu = function() {

    var isMobileCheck = function() {
        if ($(document).width() > 992) {
            return false;
        } else {
            return true;
        }
    };
    
    function toggleIcon(menuItem) {
        var id = menuItem.attr('data-id');
        var i = $("i[data-id=" + id + "]");
        if (i.hasClass("icon-right-open")) {
            i.removeClass("icon-right-open");
            i.addClass("icon-down-open");
        }
        else if(i.hasClass("icon-down-open"))
        {
            i.removeClass("icon-down-open");
            i.addClass("icon-right-open");
        }
    }

    function toggleMenu(menuItem) {
        if (isMobileCheck()) {
            menuItem.toggleClass("menu__item--opened");
            toggleIcon(menuItem);
        }
    }

    $(".menu__item").on("click", function(e) {
    var menuItem = $(this);
    toggleMenu(menuItem);
    });



    $(".menu__item__submenu__item").on("click", function(e) {
        var menuItem = $(this);
        toggleMenu(menuItem);
        e.stopPropagation();
    });
};
